<template>
  <div class="proof-page">
    <div class="detail-bg">
      <div class="proof-title">中国（湖南）自由贸易试验区数字知识产权保护平台</div>
      <div class="content-container">
        <p class="intro" v-for="(item, index) in contents" :key="index">{{ item }}</p>
      </div>
    </div>
    <div class="article-footer"></div>
  </div>
</template>

<script>

import store from '@/store'

export default {
  name: "",
  data() {
    return {
      contents: [
          '数字化确权通过技术手段为数据生成不可篡改的证据，证明数据在某一个时间点存在。用于防止数据的滥用和盗用，解决相关法律纠纷。',
          '和传统的知识产权保护手段相比，数字化确权具有保护隐私、低成本和快捷的优势。可以方便且安全地嵌入数据生产的流程中，提供更细粒度的产权证明和制造过程存证。',
          '数字化确权是传统知识产权保护手段的有力补充，在知识产权的前置确权阶段发挥作用，为传统知识产权的确权和维权提供更有力的保障。',
          '知识财产是人类在社会实践中创造的智力劳动成果。而在数字化时代, 海量知识财产（数据）的创造、管理和使用面临着前所未有的挑战。',
          '中国（湖南）自由贸易试验区数字知识产权保护平台以“数字化确权”作为知识财产保护的起点, 快速为企业及个人建立知识财产保险箱, 在保护知识产权不被侵犯的前提下, 通过隐私计算、人工智能和区块链技术促进知识财产价值的全面释放。',
          '中国（湖南）自由贸易试验区数字知识产权保护平台降低了知识财产生产、使用和保护的成本，在更大范围内促进知识财产的创造和流通，从而提升整个社会的创新能力。']
    };
  },
  mounted() {},
  methods: {},
  beforeRouteEnter(to, from, next) {
    store.commit('navbar/SET_POSITION_BOTTOM')
    let pageIndex = { activeIndex: '8'}
    store.commit('navbar/SET_ACTIVEINDEX', pageIndex)
    next()
  },
};
</script>

<style lang="stylus" scoped>

@media only screen and (min-width: 600px) {
.proof-page {
  background #f6f6f6
  display flex
  flex-direction column
}

.navbar {
  flex-grow 0
  flex-shrink 0
  height 365px
}

.detail-bg {
  margin 0 auto
  width 800px
  background white
  padding 0px 55px
  flex-grow 1
  flex-shrink 0
}

.proof-title {
  margin 72px 0px 54px 0px
  font-size 20px
  font-weight 600
  color #818181
  text-align center
  width 100%
}

.intro  {
  font-size 14px
  color #818181
  line-height 28px
  text-indent 30px
}

.content-container {
  margin-bottom 50px
}

.article-footer {
  height 80px
}

}


// mobile


@media only screen and (max-width: 600px) {
.proof-page {
  background #f6f6f6
  min-height 100%
  display flex
  flex-direction column
}

.navbar {
  flex-grow 0
  flex-shrink 0
  height 365px
}

.detail-bg {
  margin 0 auto
  background white
  padding 0px 25px
  flex-grow 1
  flex-shrink 0
}

.proof-title {
  margin 72px 0px 54px 0px
  font-size 20px
  font-weight 600
  color #818181
  text-align center
  width 100%
}

.intro  {
  font-size 14px
  color #818181
  line-height 28px
  text-indent 30px
}

.content-container {
  margin-bottom 50px
}

}
</style>