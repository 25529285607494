var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "proof-page" }, [
    _c("div", { staticClass: "detail-bg" }, [
      _c("div", { staticClass: "proof-title" }, [
        _vm._v("中国（湖南）自由贸易试验区数字知识产权保护平台")
      ]),
      _c(
        "div",
        { staticClass: "content-container" },
        _vm._l(_vm.contents, function(item, index) {
          return _c("p", { key: index, staticClass: "intro" }, [
            _vm._v(_vm._s(item))
          ])
        }),
        0
      )
    ]),
    _c("div", { staticClass: "article-footer" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }